import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { startsWith, get } from 'lodash'

import media from '../../../utils/media'

const Button = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.25s ease-in-out;
  min-height: 48px;
  padding: 10px 50px;
  border-radius: 53px;
  background-color: ${({ theme, background }) => get(theme, background, '#fff')};
  font-family: 'Source Sans Pro';
  font-size: ${({ small }) => small ? 16 : 20}px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  text-decoration: none;

  &:hover {
    transform:scale(1.05,1.05);
    text-decoration: none;
    color: #ffffff;
  }

  ${media.lessThan('sm')`
    min-height: 55px;
    text-align: center;
    font-size: 18px;
  `}
`

const ButtonHref = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.25s ease-in-out;
  min-width: 264px;
  min-height: 48px;
  padding: 10px 50px;
  border-radius: 53px;
  background-color: ${({ theme, background }) => get(theme, background, '#fff')};
  font-family: 'Source Sans Pro';
  font-size: ${({ small }) => small ? 16 : 20}px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  text-decoration: none;

  &:hover {
    padding: 10px 60px;
    text-decoration: none;
    color: #ffffff;
  }

  ${media.lessThan('sm')`
    min-height: 55px;
    text-align: center;
    font-size: 18px;
    padding: 10px 40px;
  `}
`

const LaunchButton = ({ link, title, small, background, ...props }) => {
  if (startsWith(link, 'http')) {
    return (
      <ButtonHref
        href={link}
        small={small}
        background={background}
        data-testid='launch-button'
        dangerouslySetInnerHTML={{ __html: title }}
        {...props} />
    )
  }

  return (
    <Button
      to={link}
      small={small}
      background={background}
      data-testid='launch-button'
      dangerouslySetInnerHTML={{ __html: title }}
      {...props} />
  )
}

LaunchButton.propTypes = {
  link: PropTypes.string,
  title: PropTypes.string,
  small: PropTypes.bool,
  background: PropTypes.string
}

LaunchButton.defaultProps = {
  link: '/programmes-immobiliers-neufs/',
  title: 'Lancer ma recherche',
  small: false,
  background: 'brightOrange'
}

export default LaunchButton
