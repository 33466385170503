import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import media from '../../../utils/media'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 830px;
  margin: 0 auto;
  padding: 0 24px ;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px ;
  `}

  ${media.greaterThan('lg')`
    padding: 0;
  `}
`

const FormulaContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 14px 21px;
  border-radius: 20px;
  border: 2px solid ${({ theme }) => get(theme, 'lightOrange')};
  margin: 32px auto 0;
  text-align: center;

  ${media.greaterThan('sm')`
    margin: 40px auto 0;
  `}
`

const RE2020Changes = () => (
  <Container>
    <HomeSectionTitle tag='h3'>
      Quels changements par rapport à la RT 2012 ?
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      La RT 2012 et la RE 2020 ont pour principale différence la limite des consommations énergétiques.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      En effet, la limite des consommations d’énergie des bâtiments construits sous la RE 2020 seront encore plus basses que sous la RT 2012.
    </HomeSectionDescription>
    <FormulaContainer>
      <HomeSectionDescription>
        Par ailleurs, si la RT 2012 imposait la construction de bâtiments à basse consommation (BBC) avec des normes très strictes pour l’isolation et la ventilation, <strong>la RE 2020 va encore plus loin.</strong>
      </HomeSectionDescription>
    </FormulaContainer>
  </Container>
)

export default RE2020Changes
