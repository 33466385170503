import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const StyledImageSection = styled(ImageSection)`
  max-height: fit-content;
`

const RE2020ConsoNeuf = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
      placeholderImage: file(relativePath: {eq: "RE2020/RE2020-conso-neuf.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <StyledImageSection
      small={true}
      right={true}
      imageData={placeholderImage}
      backgroundColor='veryLightOrange'>
      <HomeSectionTitle>
        Les bâtiments neufs se veulent moins gourmands
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        L’objectif de cette nouvelle réglementation est de toujours plus réduire les émissions de gaz à effet de serre des bâtiments neufs.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Par ailleurs, la qualité de vie sera améliorée et les factures d’énergie largement en baisse.
        <br />&nbsp;<br />À noter que la consommation de chauffage d’une maison individuelle ne devra pas dépasser les <strong>12 kWh/an</strong>. Concernant la consommation totale d’énergie, celle-ci ne devra pas dépasser les <strong>100 kWh/an</strong>.
      </HomeSectionDescription>
    </StyledImageSection>
  )
}

export default RE2020ConsoNeuf
