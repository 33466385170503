import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { isEqual, get } from 'lodash'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import media from '../../../utils/media'
import getMediaSize from '../../../utils/getMediaSize'
import useWindowSize from '../../../utils/useWindowSize'

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${({ $right, $reverse }) => ($right && !$reverse) || (!$right && $reverse) ? 'column' : 'column-reverse'};
  background-color: ${({ theme, $isBackground, $backgroundColor }) =>
    $isBackground ? 'transparent' : get(theme, $backgroundColor, '#FFF')};
  width: 100vw;
  height: 100%;
  align-items: center;

  ${media.greaterThan('lg')`
    flex-direction: ${({ $right }) => $right ? 'row-reverse' : 'row'};
  `}
`

const ImagePlaceholder = styled.div`
  width: 100%;
  height: auto;

  ${media.greaterThan('xl')`
    width: 50%;
  `}
`

const FixedImage = styled(GatsbyImage)`
  width: 50%;
  height: 345px;
  transform: ${({ transformScale }) => get(transformScale, 'xs.transform', 'scale(2)')};
 
  ${media.greaterThan('sm')`
    margin: 16px auto 40px;
    transform: ${({ transformScale }) => get(transformScale, 'sm.transform', 'scale(1.6)')};
    max-width: ${({ size }) => get(size, 'sm.maxWidth', '100%')};
  `}

  ${media.greaterThan('md')`
    height: 400px;
    margin: 32px auto;
    transform: ${({ transformScale }) => get(transformScale, 'md.transform', 'scale(1.5)')};
  `}

  ${media.greaterThan('lg')`
    height: auto;
    transform: ${({ transformScale }) => get(transformScale, 'lg.transform', 'scale(1.1)')};
  `}

  ${media.greaterThan('xl')`
    margin: 0; 
    transform: ${({ transformScale }) => get(transformScale, 'xl.transform', 'scale(0.9)')};
    max-width: ${({ size }) => get(size, 'xl.maxWidth', '50vw')};
  `}

  ${media.greaterThan('xxl')`
    margin: 0;
    transform: ${({ transformScale }) => get(transformScale, 'xxl.transform', 'scale(0.9)')};
  `}
`

const Image = styled(GatsbyImage)`
  width: 100%;
  height: auto;
  max-height: fit-content;
  max-block-size: available;

  ${media.lessThan('sm')`
    height: ${({ size }) => get(size, 'xs.height', '250')}px;
    max-width: ${({ size }) => get(size, 'md.maxWidth', '100%')};
  `}

  ${media.greaterThan('sm')`
    height: ${({ size }) => get(size, 'sm.height', '350')}px;
  `}

  ${media.greaterThan('md')`
    height: ${({ size }) => get(size, 'md.height', '450')}px;

  `}

  ${media.greaterThan('lg')`
    height: ${({ size }) => get(size, 'lg.height')}px;
    max-width: ${({ size }) => get(size, 'lg.maxWidth', '50%')};
  `}

  ${media.greaterThan('xl')`
    height: ${({ size }) => get(size, 'xl.height')}px;
  `}

  ${media.greaterThan('xxl')`
    height: ${({ size }) => get(size, 'xxl.height')}px;
  `}

  ${media.greaterThan('xxxl')`
    height: ${({ size }) => get(size, 'xxxl.height')}px;
  `}
`

const TextContainer = styled.div`
  display: flex;
  height: auto;
  flex-direction: column;
  justify-content: center;
  padding: 56px 24px;
  align-items: ${({ $right }) => $right ? 'flex-end' : 'flex-start'};

  ${media.greaterThan('xl')`
    margin-left: 0;
    ${({ $right }) => $right ? 'padding-right: 40px;' : 'padding-left: 40px;'}
  `}

  ${media.greaterThan('sm')`
    padding: 72px 40px;
  `}

  ${media.between('md', 'lg')`
    padding: 72px;
  `}

  ${media.between('lg', 'xl')`
    padding: 72px 40px;
    width: 570px;
  `}

  ${media.lessThan('lg')`
    align-items: center;
  `}

`

const TextContent = styled.div`
  ${media.greaterThan('lg')`
    max-width: ${({ max }) => max}px;
  `}
`

TextContent.propTypes = {
  max: PropTypes.number
}

TextContent.defaultProps = {
  max: 600
}

const BackgroundImage = styled(GatsbyImage)`
  background-size:cover;
  object-fit:cover;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  height:550px;
`

const ImageBlock = ({ imageData, isBackground, size, alt, fixed, transformScale, ...props }) => {
  const image = getImage(imageData)
  const { width } = useWindowSize()
  const isDesktop = width > getMediaSize('xl')

  if (isEqual(isBackground, true) && isEqual(isDesktop, true)) {
    return (
      <>
        <ImagePlaceholder />
        <BackgroundImage
          alt={alt}
          image={image}
          imgStyle={{ objectPosition: 'left bottom' }} />
      </>
    )
  }

  if (fixed) {
    return (
      <FixedImage
        alt={alt}
        size={size}
        image={image}
        imgStyle={{ objectFit: 'contain' }}
        transformScale={transformScale} />
    )
  }

  return (
    <Image
      alt={alt}
      size={size}
      image={image}
      imgStyle={{ objectFit: 'cover' }} />
  )
}

ImageBlock.propTypes = {
  alt: PropTypes.string.isRequired,
  size: PropTypes.object.isRequired,
  fixed: PropTypes.bool.isRequired,
  imageData: PropTypes.object.isRequired,
  isBackground: PropTypes.bool.isRequired
}

const ImageSection = ({
  right, fixed, reverse, imageData, isBackground, backgroundColor, children, maxText,
  size, alt, transformScale, ...props
}) => (
  <Container
    $right={right}
    $reverse={reverse}
    $isBackground={isBackground}
    $backgroundColor={backgroundColor}>
    <ImageBlock
      {...props}
      alt={alt}
      size={size}
      fixed={fixed}
      imageData={imageData}
      isBackground={isBackground}
      transformScale={transformScale} />
    <TextContainer
      $right={right}
      {...props}>
      <TextContent
        max={maxText}
        $right={right}>
        {children}
      </TextContent>
    </TextContainer>
  </Container>
)

ImageSection.propTypes = {
  alt: PropTypes.string,
  size: PropTypes.object,
  fixed: PropTypes.bool,
  right: PropTypes.bool,
  maxText: PropTypes.number,
  reverse: PropTypes.bool,
  children: PropTypes.node.isRequired,
  imageData: PropTypes.object.isRequired,
  isBackground: PropTypes.bool,
  backgroundColor: PropTypes.string,
  transformScale: PropTypes.object
}

ImageSection.defaultProps = {
  alt: 'Folhomee, agrégateur expert en immobilier neuf',
  size: {},
  right: false,
  static: false,
  reverse: false,
  isBackground: false,
  backgroundColor: 'transparent'
}

export default ImageSection
