import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import Header from '../../atoms/HeroHeader'
import Subtitle from '../../atoms/HeroSubtitle'
import Container from '../../atoms/HeroContainer'
import Description from '../../atoms/HeroDescription'

const StyledContainer = styled(Container)`
  max-width: 800px;
  margin: auto;

  ${media.lessThan('sm')`
    padding-top: 100px;
    padding-bottom: 80px;
  `}

  ${media.greaterThan('xl')`
    max-width: 680px;
    margin-right: 55%;
    padding-left: 40px;
  `}
`

const StyledSubtitle = styled(Subtitle)`
  ${media.greaterThan('xl')`
    padding-right: 32px;
  `}
`

const StyledDescription = styled(Description)`
  ${media.greaterThan('xl')`
    padding-right: 32px;
  `}
`

const re2020 = () => (
  <>
    <StyledContainer>
      <Header>
        Tout savoir sur la nouvelle RE 2020
      </Header>
      <StyledSubtitle>
        La RE 2020 fait suite au Grenelle de l’environnement réalisé cette même année. Il s’agit tout simplement de la nouvelle réglementation pour les constructions à venir.
      </StyledSubtitle>
      <StyledDescription>
        Cette réglementation s’applique à partir de l’année 2022. Globalement, la RE 2020 reprend la plupart des éléments de la RT 2012, en ajoutant encore plus d’exigences pour les constructeurs. Quoi qu’il en soit, le but est d’imposer aux promoteurs immobiliers la construction de logements à énergie positive. Folhomee revient sur les caractéristiques de la RE 2020.
      </StyledDescription>
    </StyledContainer>
  </>
)

export default re2020
