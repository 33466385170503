import React from 'react'
import styled from 'styled-components'
import { get } from 'lodash'

import media from '../../../utils/media'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import RE2020PhonesImage from '../../molecules/RE2020PhonesImage'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Container = styled.div`
  width: 100%;
  background-color: ${({ theme }) => get(theme, 'paleBlue')};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  ${media.lessThan('lg')`
    flex-direction: column-reverse;
  `}
`

const StyledCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  height: 50%;

  ${media.lessThan('lg')`
    padding-bottom: 72px;
    margin-bottom:0px
  `}

  ${media.greaterThan('lg')`
    width:50%;
    max-width: 500px;
  `}
`

const RightCol = styled(StyledCol)`
  width:auto;
  margin: 56px 24px 0;

  ${media.greaterThan('sm')`
    margin: 72px 40px 0;
  `}

  ${media.greaterThan('md')`
    margin: 72px;
    margin-bottom:0;
  `}

  ${media.greaterThan('lg')`
    width:50%;
    max-width: 600px;
    margin:72px 40px 72px 0;
    align-items:start;
    padding:0;
  `}
`

const RE2020Investir = () => (
  <Container>
    <StyledCol>
      <RE2020PhonesImage />
    </StyledCol>
    <RightCol>
      <HomeSectionTitle tag='h2'>
        Pourquoi investir dans un programme immobilier neuf RE 2020 ?
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Tout comme les bâtiments construits sous la norme RT 2012, les prochaines constructions RE 2020 vont permettre aux acquéreurs de réaliser de grandes économies sur leurs factures énergétiques.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Bien que le prix d’achat soit plus élevé sur ce type de logement, les investisseurs ont tout à y gagner : leur achat leur permettra de bénéficier d’une plus-value non négligeable lors de la revente.
      </HomeSectionDescription>
    </RightCol>
  </Container>
)

export default RE2020Investir
