import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import SEO from '../components/atoms/Seo'
import media from '../utils/media.js'
import Navbar from '../components/organisms/Navbar'
import Footer from '../components/organisms/NewFooter'
import SiteSection from '../components/atoms/SiteSection'
import RE2020Norme from '../components/organisms/RE2020Norme'
import RE2020Carac from '../components/organisms/RE2020Carac'
import RE2020Regles from '../components/organisms/RE2020Regles'
import RE2020Changes from '../components/organisms/RE2020Changes'
import RE2020Energie from '../components/organisms/RE2020Energie'
import RE2020Criteres from '../components/organisms/RE2020Criteres'
import RE2020Investir from '../components/organisms/RE2020Investir'
import RE2020ConsoNeuf from '../components/organisms/RE2020ConsoNeuf'
import RE2020TopSection from '../components/organisms/RE2020TopSection'
import RE2020InvestLocatif from '../components/organisms/RE2020InvestLocatif'
import RE2020Specifications from '../components/organisms/RE2020Specifications'

const TITLE = 'Tout savoir sur la nouvelle RE 2020'
const DESCRIPTION = 'Folhomee explicite tout ce qu\'il faut savoir sur la nouvelle réglementation environnementale RE 2020.'

const StyledSiteSection = styled(SiteSection)`
  ${media.greaterThan('xl')`
    height: 420px;
  `}
`

const Re2020 = ({ location }) => (
  <>
    <SEO
      title={TITLE}
      description={DESCRIPTION} />
    <Navbar location={location} />
    <RE2020TopSection />
    <SiteSection id='RE2020-caracteristique' $first={true}>
      <RE2020Carac />
    </SiteSection>
    <SiteSection id='RE2020-normes'>
      <RE2020Norme />
    </SiteSection>
    <SiteSection id='RE2020-criteres'>
      <RE2020Criteres/>
    </SiteSection>
    <SiteSection id='RE2020-specifications'>
      <RE2020Specifications/>
    </SiteSection>
    <SiteSection id='RE2020-conso-neuf'>
      <RE2020ConsoNeuf/>
    </SiteSection>
    <SiteSection id='RE2020-changes'>
      <RE2020Changes />
    </SiteSection>
    <StyledSiteSection id='RE2020-regles'>
      <RE2020Regles />
    </StyledSiteSection>
    <SiteSection id='RE2020-energie'>
      <RE2020Energie />
    </SiteSection>
    <SiteSection id='RE2020-Investir'>
      <RE2020Investir />
    </SiteSection>
    <SiteSection id='RE2020-invest-locatif' $last={true}>
      <RE2020InvestLocatif />
    </SiteSection>
    <Footer noImage={true} noForm={true} />
  </>
)

Re2020.propTypes = {
  location: PropTypes.object.isRequired
}

export default Re2020
