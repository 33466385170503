import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const StyledImageSection = styled(ImageSection)`
  max-height: fit-content;
`

const RE2020Norme = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
      placeholderImage: file(relativePath: {eq: "RE2020/RE2020-normes.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <StyledImageSection
      small={true}
      imageData={placeholderImage}
      backgroundColor='paleGrey'>
      <HomeSectionTitle tag='h3'>
        Norme RE 2020, comment ça marche ?
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        La RE 2020 est une réglementation au même titre que la RT 2012.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Le but est de fixer des objectifs de consommation aux constructeurs, qui doivent respecter un cahier des charges dans le cadre de la construction de nouveaux programmes immobiliers.
      </HomeSectionDescription>
    </StyledImageSection>
  )
}

export default RE2020Norme
