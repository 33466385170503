import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import RE2020 from '../../molecules/RE2020'
import TopSection from '../../molecules/TopSection'
import RE2020Image from '../../molecules/RE2020Image'

const StyledRE2020Image = styled(RE2020Image)`
  z-index: -1;
  display: none;
  ${media.greaterThan('xl')`
    display: block;
    height: 586px; 
    top: 220px;
  `}
`

const RE2020TopSection = () => (
  <TopSection component={StyledRE2020Image}>
    <RE2020 />
  </TopSection>
)

export default RE2020TopSection
