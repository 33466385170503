import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1054px;
  margin: 0 auto;
  padding: 0 24px;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px ;
  `}
`

const RE2020Carac = () => (
  <Container>
    <HomeSectionTitle tag='h2'>
      Norme RE 2020, quelles sont les caractéristiques ?
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      La RE 2020 vise à remplacer la RT 2012 pour les nouvelles constructions à venir.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Il s’agit de la réglementation la plus récente, en vigueur pour les programmes immobiliers voyant le jour à partir de 2022. Le but est de construire des bâtiments à énergie positive : les nouveaux bâtiments doivent non seulement consommer très peu d’énergie, mais également en produire via des sources d’énergie renouvelables.
    </HomeSectionDescription>
  </Container>
)

export default RE2020Carac
