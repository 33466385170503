import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import LaunchButton from '../../atoms/LaunchButton'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const StyledButton = styled(LaunchButton)`
  margin-top: 15px;

  ${media.greaterThan('md')`
    min-width: 395px;
  `}
`

const StyledButtonContainer = styled(ButtonContainer)`
  ${media.lessThan('lg')`
    margin-bottom: 100px;
  `}
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 830px;
  margin: 0 auto;
  padding: 0 24px ;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px ;
  `}

  ${media.greaterThan('lg')`
    padding: 0;
  `}
`

const RE2020Changes = () => (
  <Container>
    <HomeSectionTitle>
      L’investissement locatif en RE 2020
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Par ailleurs, dans le cadre d’un investissement locatif, les logements RE 2020 permettront de recevoir des revenus réguliers élevés, notamment grâce aux équipements électroménagers de qualité et une isolation optimale.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      Somme toute, les normes de la RE 2020 sont donc très positives pour les acheteurs, qui investissent dans un bien peu énergivore, avec des factures considérablement réduites sur la durée.
    </HomeSectionDescription>
    <StyledButtonContainer>
      <StyledButton
        link='/programmes-immobiliers-neufs'
        title='Parcourez les programmes immobiliers neufs'
        background='brightOrange' />
    </StyledButtonContainer>
  </Container>
)

export default RE2020Changes
