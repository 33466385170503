import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'

import media from '../../../utils/media'

const RE2020PhonesImage = props => {
  const data = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "RE2020/RE2020-investir.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <GatsbyImage
      alt='RE 2020'
      image={data.placeholderImage.childImageSharp.gatsbyImageData}
      {...props} />
  )
}

const StyledImage = styled(RE2020PhonesImage)`
  width: 476px;
  height: auto;

  ${media.lessThan('sm')`
    width: 355px;
  `}
`

export default StyledImage
