import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import PictoHeater from '../../../assets/heater.inline.svg'
import PictoIsolation from '../../../assets/isolation.inline.svg'
import PictoAirQuality from '../../../assets/air-quality.inline.svg'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PictoLineContainer from '../../molecules/PictoLineContainer'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import PictoRenewableEnergy from '../../../assets/renewable-energy.inline.svg'
import PictoCarbonFootprint from '../../../assets/carbon-footprint.inline.svg'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const DETAILS_LINE = [{
  icon: PictoHeater,
  description: 'Une <strong>consommation de chauffage </strong>en dessous de 12 kWhEP/m2 par an'
}, {
  icon: PictoAirQuality,
  description: 'Un certain seuil de <strong>qualité de l’air intérieur</strong>'
}, {
  icon: PictoIsolation,
  description: 'Un seuil minimum d’isolation <strong>phonique et thermique</strong>'
}, {
  icon: PictoRenewableEnergy,
  description: 'Une présence d’une source de production <strong>d’énergie renouvelable</strong>'
}, {
  icon: PictoCarbonFootprint,
  description: 'Une réduction de <strong>l’empreinte carbone</strong> de la construction'
}]

const Container = styled.div`
  margin: 0 auto;
  max-width: 830px;
  padding: 0 24px;
  
  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px ;
  `}

  ${media.greaterThan('lg')`
    padding: 0;
  `}
`

const LineContainer = styled.div`
  gap: 24px;
  display: flex;
  justify-content: center;
  margin: 32px auto;
  flex-wrap: wrap;

  ${media.greaterThan('sm')`
    gap: 40px;
    margin: 40px auto;
  `}
`

const DetailContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 8px;
  width: 100%;
  padding: 0 80px;
  
    ${media.greaterThan('sm')`
      width: 240px;
      padding: 0;
      gap: 16px;
    `}

    ${media.greaterThan('lg')`
      width: 250px;
    `}


  & > svg {
    height: 80px;
    width: 80px;
    min-width: 80px;

    ${media.lessThan('sm')`
      height: 60px;
      min-width: 60px;
    `}
  }

  & > p {
    margin: 0;
  }
`
const DetailTitle = styled(HomeSectionDescription)`
  margin-top: 8px;
  text-align: center;
`

const RE2020Criteres = () => (
  <Container>
    <HomeSectionTitle>
      Les critères pour obtenir la norme RE 2020
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Les bâtiments construits à partir de 2022 doivent remplir les critères suivants :
    </HomeSectionSubtitle>
    <PictoLineContainer details={DETAILS_LINE}/>
  </Container>
)

export default RE2020Criteres
