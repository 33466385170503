import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'

const PhoneRE2020 = props => {
  const data = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "RE2020/RE2020-energie.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <GatsbyImage
      alt='Folhomee, agrégateur expert en immobilier neuf, RE2020'
      image={data.placeholderImage.childImageSharp.gatsbyImageData}
      {...props} />
  )
}

const StyledImage = styled(PhoneRE2020)`
  width: 400px;
`

export default StyledImage
