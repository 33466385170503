import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const size = {
  lg: {
    height: '420'
  }
}

const RE2020Regle = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
      placeholderImage: file(relativePath: {eq: "RE2020/RE2020-regles.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <ImageSection
      size={size}
      imageData={placeholderImage}
      backgroundColor='paleGrey'>
      <HomeSectionTitle>
        La réglementation en vigueur depuis le 1er janvier 2022
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Cette réglementation prévoit effectivement le développement de programmes immobiliers BEPOS.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Ces bâtiments devraient être en mesure de produire plus d’électricité et de chaleur que ce qu’ils consomment, notamment grâce à l’intégration de systèmes de production d’énergie renouvelable. Le surplus d’énergie permettra aux propriétaires de revendre ou bien de réinjecter l’énergie dans le réseau électrique public.
      </HomeSectionDescription>
    </ImageSection>
  )
}

export default RE2020Regle
