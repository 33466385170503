import React from 'react'
import styled from 'styled-components'
import media from '../../../utils/media'
import PropTypes from 'prop-types'
import { get, map, range } from 'lodash'

const StyledListUnordered = styled.ul`
  list-style: none;
  margin: 16px 0;

  & > li {
    margin-top: 11px;
    margin-left: 1em;
    text-indent: -.5em;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    line-height: 1.5;
    color: ${({ theme }) => get(theme, 'blue', '#FFF')};

    ${media.lessThan('sm')`
      font-size: 15px;
    `}

    &:first-of-type {
      margin-top:0;
    }

    &::before {
      content: "•";
      color: ${({ theme }) => get(theme, 'brightOrange', '#fff')};
      font-weight: bold;
      display: inline-block;
      width: .5em;
    }

    strong {
      font-weight: 600;
    }

    a {
      font-weight: 600;
      color: ${({ theme }) => get(theme, 'brightOrange', '#fff')};
      text-decoration: none;
    }
  }
`

const StyledListOrdered = styled.ol`
  list-style: none;
  margin: 16px 0;

  & > li {
    margin-top: 16px;
    margin-left: 32px;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    line-height: normal;
    color: ${({ theme }) => get(theme, 'blue', '#FFF')};
    position: relative;

    ${media.lessThan('sm')`
      font-size: 15px;
    `}

    &::before {
      position: absolute;
      text-align: center;
      left: -32px;
      color: ${({ theme }) => get(theme, 'white', '#fff')};
      background-color: ${({ theme }) => get(theme, 'brightOrange', '#fff')};
      font-weight: bold;
      display: inline-block;
      width: 20px;
      height: 20px;
      border-radius: 100%;
    }

    ${map(range(1, 10), idx => `
      &:nth-of-type(${idx}) {
        &::before {
          content: "${idx}";
        }
      }
    `)}

    strong {
      font-weight: 600;
    }

    a {
      font-weight: 600;
      color: ${({ theme }) => get(theme, 'brightOrange', '#fff')};
      text-decoration: none;
    }
  }

  li:first-of-type {
    margin-top:0;
  }
`

const StyledList = ({ ordered, children, ...props }) => {
  if (ordered) {
    return (
      <StyledListOrdered {...props}>
        {children}
      </StyledListOrdered>
    )
  }

  return (
    <StyledListUnordered {...props}>
      {children}
    </StyledListUnordered>
  )
}

StyledList.propTypes = {
  ordered: PropTypes.bool,
  children: PropTypes.any
}

StyledList.defaultProps = {
  ordered: false
}

export default StyledList
