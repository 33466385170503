import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import PhoneRE2020 from '../../molecules/RE2020Phone'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Container = styled.div`
  display: flex;
  max-width: 830px;
  margin: 0 auto;
  padding: 0 24px ;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px ;
  `}

  ${media.greaterThan('lg')`
    padding: 0;
    flex-direction: row ;
  `}
`

const StyledCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: center;
  height: 50%;

  ${media.greaterThan('lg')`
    width:50%;
    max-width: 500px;
  `}
`

const LeftCol = styled(StyledCol)`
  width:50%;
  max-width: 600px;
  margin:72px 40px 72px 0;
  align-items:start;
  padding:0;

  ${media.lessThan('lg')`
    margin:0;
    width:100%;
  `}
`

const RE2020Energie = () => (
  <Container>
    <LeftCol>
      <HomeSectionTitle>
        L’énergie au coeur de la  norme RE 2020
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        La RE 2020 prend en compte davantage d’usages de l’énergie.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Comme par exemple les appareils électroménagers prévus pour les logements, ou bien les appareils et les matériaux utilisés lors de la construction.
        <br />&nbsp;<br /><strong>Le but est, globalement, de supprimer complètement le gaspillage énergétique.</strong>
      </HomeSectionDescription>
    </LeftCol>
    <StyledCol>
      <PhoneRE2020 />
    </StyledCol>
  </Container>
)

export default RE2020Energie
