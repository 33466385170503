import React from 'react'
import styled from 'styled-components'

import media from '../../../utils/media'
import StyledList from '../../atoms/StyledList'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 830px;
  padding: 0 24px ;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px ;
  `}

  ${media.greaterThan('lg')`
    padding: 0;
  `}
`

const RE2020Specifications = () => (
  <Container>
    <HomeSectionTitle tag='h3'>
      Le cahier des charges à remplir pour respecter la&nbsp;norme&nbsp;RE&nbsp;2020
    </HomeSectionTitle>
    <HomeSectionSubtitle>
      Les constructeurs dont le permis de construire est purgé durant l’année 2022 ont pour obligation de construire un bâtiment respectant les normes de la RE 2020.
    </HomeSectionSubtitle>
    <HomeSectionDescription>
      La RE 2020 comprend différents points à ne pas négliger par les promoteurs&nbsp;:
    </HomeSectionDescription>
    <StyledList>
      <li>
        <strong>Limitation de la consommation énergétique </strong>des appareils ménagers installés dans les logements
      </li>
      <li>
        <strong>Prévoir des systèmes de récupération </strong>des eaux de pluie
      </li>
      <li>
        <strong>Installer des fenêtres qualitatives</strong>, très isolantes
      </li>
      <li>
        <strong>Prévoir l’utilisation d’énergies renouvelables </strong>ou de technologies permettant la production d’énergie par le bâtiment (panneaux solaires, puits canadiens, etc)
      </li>
      <li>
        <strong>Étudier l’emplacement de l’orientation du bâtiment</strong> pour profiter des apports du soleil
      </li>
      <li>
        <strong>Prévoir une ventilation </strong>telle que la VMC à double flux ou une pompe à chaleur
      </li>
      <li>
        <strong>Prévoir une ventilation </strong>telle que la VMC à double flux ou une pompe à chaleur
      </li>
    </StyledList>
  </Container>
)

export default RE2020Specifications
