import React from 'react'
import styled from 'styled-components'
import { map } from 'lodash'

import media from '../../../utils/media'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const LineContainer = styled.div`
  gap: 24px;
  display: flex;
  justify-content: center;
  margin: 32px auto;
  flex-wrap: wrap;

  ${media.greaterThan('sm')`
    gap: 40px;
    margin: 40px auto;
  `}
`

const DetailContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 8px;
  flex:1;
  padding: 0 96px;
  
  ${media.greaterThan('sm')`
    padding: 0;
    min-width:200px;
  `}

  & > svg {
    height: 80px;
    width: 80px;
    min-width: 80px;

    ${media.lessThan('sm')`
      height: 60px;
      min-width: 60px;
    `}
  }
`

const DetailTitle = styled(HomeSectionSubtitle)`
  margin: 0;
  text-align: center;
`

const DetailDescription = styled(HomeSectionDescription)`
  margin: 0;
  text-align: center;
`

const PictoLineContainer = ({ details }) => (
  <LineContainer >
  {map(details, ({ title, icon: Icon, description }) => (
  <DetailContainer key={title}>
    <Icon />
    <DetailTitle dangerouslySetInnerHTML={{ __html: title }} />
    <DetailDescription dangerouslySetInnerHTML={{ __html: description }} />
  </DetailContainer>
  ))}
  </LineContainer>
)

export default PictoLineContainer
